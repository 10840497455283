<template>
  <PageWithLayout>
    <!-- <Search
      :searchDataList="viewModel.searchDataList"
      @onSearch="viewModel.onSearch()"/> -->
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
      <!-- <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="신규가맹점 등록"
          routeName="STORE_STORE_REGISTER">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template> -->
    </Board>
    <template v-slot:popup>
      <ContractDetailPopup
        v-if="viewModel.isContractDetailPopup"
        :contractData="viewModel.contractDetailData"
        @onClickContractDetailPopupClose="viewModel.onClickContractDetailPopupClose()"
        @onClickContractReject="viewModel.onClickContractReject()"
        @onClickContractApproved="viewModel.onClickContractApproved()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import ContractDetailPopup from '@/views/store/contract/view/popup/ContractDetailPopup'
// viewModel
import ContractListViewModel from '@/views/store/contract/viewModel/ContractListViewModel'

export default {
  name:'ContractList',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    IconSvg,

    ContractDetailPopup,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new ContractListViewModel(),
      searchTypeDataList:[
        { id:'ID', text:'코스' },
      ],
      searchData:{
        searchType:'ID',
        searchValue:''
      }
    }
  },
}
</script>