import router from '@/router';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class ContractListViewModel {
  constructor() {
    this.boardData = {
      title:'승인대기',
      drawDataList: [
        {
          title:'결재상태',
          width:'120px',
          value:'editStatus',
          filter:{
            name:'convertIdToText',
            value:'contract_type'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'contract_type'
            },
            badgeFill: false
          },
        },
        {
          title:'법인명/상호',
          width:'',
          value:'name',
          isAlignLeft: true,
          isEllip: true,
        },
        {
          title:'요청자',
          width:'120px',
          value:'requester.name',
          emptyValueText:'-',
        },
        {
          title:'요청일',
          width:'100px',
          value:'requestedAt',
          filter:{
            name:'convertDateFormat',
            value:'YYYY-MM-DD'
          },
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
        rowIdValue: 'uid'
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isContractDetailPopup = false;
    this.contractDetailData = {}
  }
  init(){
    this.getContractList();
  }
  onSearch(){
    this.getContractList();
  }
  onClickRow(rowId,rowData){
    this.isContractDetailPopup = true;
    this.contractDetailData = rowData;
  }
  onClickContractDetailPopupClose(){
    this.isContractDetailPopup = false;
    this.contractDetailData = {};
  }
  onClickContractReject(){
    this.postContractApproval('REJECTED')
  }
  onClickContractApproved(){
    this.postContractApproval('APPROVED')
  }
  getContractList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.STORE_CONTRACT_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postContractApproval(status){
    const path = `${apiPath.STORE_CONTRACT_APPROVAL}`;
    const data = {
      uid : this.contractDetailData.uid,
      name : this.contractDetailData.name,
      status : status,
      vaild : true
    }
    const statusText = status === 'REJECTED' ? '반려' : '승인';
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.contractDetailData = {};
      this.isContractDetailPopup = false;
      this.getContractList();
      store.dispatch('commonToast/fetchToastStart', `${statusText}처리 되었습니다`);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}