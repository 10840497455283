<template>
  <Popup
    title="계약 정보 변경요청 상세"
    :maxWidth="800"
    closeBtnText="닫기"
    :compeleteBtnText="contractData.editStatus === 'REQUESTED' ? '위 내용으로 변경 승인합니다' : ''"
    @onClickClose="$emit('onClickContractDetailPopupClose')"
    @onClickComplete="$emit('onClickContractApproved')">
    <TableView>
      <!-- <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">계약정보</strong>
        </div>
      </template> -->
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>결재상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                badgeSize="small"
                :text="contractData.editStatus | convertIdToText('contract_type')"
                :badgeStyle="contractData.editStatus | convertIdToColor('contract_type')" />
            </span>
          </td>
        </tr>
        <tr>
          <th>요청자</th>
          <td>{{ contractData.requester.name }}</td>
        </tr>
        <tr>
          <th>요청일시</th>
          <td>{{ contractData.requestedAt | convertDateFormat }}</td>
        </tr>
        <tr>
          <th>법인명/상호</th>
          <td>{{ contractData.name }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">계약정보</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="is_active"
              text="활성"
              offText="비활성"
              :isDisabled="true"
              @update:value="value => $store.dispatch('commonToast/fetchToastStart', `수정내용을 저장하여야 상태가 변경됩니다`)" />
            <p class="desc_info">💡 비활성 상태로 변경하면, 해당 가맹점의 결제기능이 차단됩니다.</p>
          </td>
        </tr>
        <tr>
          <th>정산계좌 은행</th>
          <td>
            {{ contractData.bankName | convertIdToText('account_bank') }}
          </td>
        </tr>
        <tr>
          <th>정산계좌번호</th>
          <td>
            {{ contractData.bankAccount }}
          </td>
        </tr>
        <tr>
          <th>정산계좌 예금주</th>
          <td>
            {{ contractData.bankAccountName }}
          </td>
        </tr>
        <tr>
          <th>수수로율</th>
          <td>
            {{ contractData.fee }}%
          </td>
        </tr>
        <tr>
          <th>정산주기</th>
          <td>
            {{ contractData.settlementPeriod | convertIdToText('settle_cycle_type') }}
          </td>
        </tr>
        <!-- <tr>
          <th>가맹 신청서 첨부</th>
          <td>
          </td>
        </tr> -->
      </template>
    </TableView>
    <template v-slot:footBtn>
      <Button
        v-if="contractData.editStatus === 'REQUESTED'"
        btnSize="small"
        btnStyle="fourthly"
        text="반려합니다"
        @onClickBtn="$emit('onClickContractReject')"/>
    </template>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name:'ContractDetailPopup',
  components:{
    Popup,
    TableView,
    Badge,
    SwitchCheck,
    Button,
  },
  props:{
    contractData: Object,
  },
  computed:{
    is_active:{
      get(){
        return this.contractData.status === 'REGISTERED'
      },
      set(val){
        return this.contractData.status = val ? 'REGISTERED' : 'UNREGISTERED'
      }
    }
  },
}
</script>